@import "../../../var.scss";

.modal-header {
    padding: 1.5rem 2rem;
}

.tow_button_card {
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-top: 5rem;
    position: relative;
    padding-top: 3rem;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -3rem;
        width: calc(100% + 6rem);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
}

.themeModal {
    .modal-dialog {
        max-width: 800px;
        .modal-content {
            background: $scondary-color;
            .modal-header {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                .modal-title {
                    font-size: 22px;
                    font-weight: 500;
                }
                button {
                    border: 0;
                    box-shadow: none;
                    background: transparent;
                    color: rgba(255, 255, 255, 0.7);
                    padding: 0;
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 35px;
                    font-weight: 300;
                    line-height: 1;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        color: #fff;
                    }
                }
            }
            .modal-body {
                padding: 60px 40px 40px;
            }
            .modal-footer {
                border-color: rgba(255, 255, 255, 0.2);
                gap: 15px;
                padding: 20px 40px;
                .themeBtn {
                    min-height: 45px;
                    padding: 5px 25px;
                    border-radius: 5px;
                    margin: 0;
                }
                .themeBtn_2 {
                    min-height: 40px;
                    padding: 5px 25px;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.05rem;
                }
            }
        }
    }
}

.themeModal_2 {
    .modal-dialog {
        max-width: 500px;
        .modal-content {
            background: $scondary-color;
            .modal-header {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                .modal-title {
                    font-size: 25px;
                    font-weight: 400;
                }
                button {
                    border: 0;
                    box-shadow: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 1;
                    transition: all 0.3s ease-in-out;
                    filter: invert(1);
                    opacity: 1;
                    z-index: 999;

                    &:hover {
                        color: #fff;
                    }
                }
            }
            .modal-body {
                padding: 30px;
                .deleteNote {
                    color: #fff;
                    text-align: center;
                    font-size: 20px;
                }
            }
            .modal-footer {
                border-color: rgba(255, 255, 255, 0.2);
                gap: 15px;
                padding: 20px 40px;
                .themeBtn {
                    min-height: 40px;
                    padding: 5px 25px;
                    border-radius: 5px;
                    margin: 0;
                }
                .themeBtn_2 {
                    min-height: 40px;
                    padding: 5px 25px;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.05rem;
                }
            }
        }
    }
}

.themeModal_2.logout_modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 0;
                border-bottom: 0;
                
                button.btn-close {
                    position: absolute;
                    right: 2rem;
                    top: 2rem;
                }
            }

            .modal-body {
                padding: 20px 40px;

                .deleteNote {
                    font-size: 28px;
                    margin-bottom: 1.5rem;
                }
                .description_logout {
                    text-align: center;
                    color: #ffffff9e;
                    font-size: 18px;
                }
            }

            .img_box_logout {
                text-align: center;
                margin: 4rem 0 1rem;
                color: #ffc107;
            }
        }
    }

    .tow_button_card {
        padding-top: 2rem;

        &::after {
            left: -4rem;
            width: calc(100% + 8rem);
        }
    }
    
    
}

@media (max-width: 767.98px) {
    .themeModal {
        .modal-dialog {
            padding: 0 15px;
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-size: 22px;
                    }
                }
                .modal-body {
                    padding: 40px 25px;
                }
                .modal-footer {
                    padding: 20px 25px;
                }
            }
        }
    }
    .themeModal_2 {
        .modal-dialog {
            padding: 0 15px;
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-size: 22px;
                    }
                }
                .modal-body {
                    padding: 40px 25px;
                }
                .modal-footer {
                    padding: 20px 25px;
                }
            }
        }
    }
}
