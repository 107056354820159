@import '../var.scss';

.page_container {
    min-height: 100vh;
    padding-left: 315px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
}

.main_content {
    padding: 130px 40px 40px;
    min-height: 100vh;
    background: #002550;
}


.folded {
    display: none;
}



.dashBoard_overLay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 50;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
}




@media only screen and (min-width: 992px) {
    .is-folded .page_container {
        padding-left: 80px
    }
    .is-folded .folded {
        display: block;
    }
    
    .is-folded .notFolded {
        display: none;
    }
}

@media only screen and (max-width: 991.98px) {
    .page_container {
        padding-left: 0px
    }
    .is-folded .dashBoard_overLay {
        visibility: visible;
        opacity: 1;
    }

    .main_content {
        padding: 130px 15px 40px;
    }
}


@media (max-width:767.98px) {
    .main_content {
        padding: 110px 15px 40px;
    }
}