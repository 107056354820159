@import "../../var";

.titleTableWeek {
    font-size: 14px;
    padding-left: 20px;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 20px;
}

.paginationParent {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    .pagination {
        margin-left: auto;
    }
}

.sortViewPagination {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 300;
    font-size: 14px;
    .form-select {
        padding-right: 35px;
        min-height: 40px;
        border-radius: 6px;
        border-width: 2px;
        font-size: 15px;
        background-size: 12px;
        border-width: 1px;
    }
}

.searchAddRowTable {
    display: flex;
    align-items: center;
    margin: 0 0 30px;
    gap: 20px;

    .inputGroupSearch {
        position: relative;

        .form-control {
            border: 1px solid #255c9d;
            min-height: 45px;
            border-radius: 8px;
            min-width: 220px;

            &::placeholder {
                padding-left: 0;
            }
            &.select_white {
                background-image: url(../../../public/images/dropdown_white.svg);
                background-size: 15px;
                background-repeat: no-repeat;
                background-position: center right 2rem;
            }
        }
        button {
            padding: 0;
            border: 0;
            box-shadow: none;
            outline: 0;
            position: absolute;
            right: 0;
            height: 100%;
            top: 0;
            width: 50px;
            font-size: 25px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 8px 8px 0;
            background: transparent;
            color: #d5d5d5;
        }
    }
    .themeBtn_2 {
        display: flex;
        margin-left: auto;
        width: auto;
        font-size: 16px;
        gap: 10px;
        border-radius: 8px;
        min-height: 45px;
        align-items: center;
        min-width: 120px;
    }

    &.withdraw_request_page {
        .themeBtn_2 {
            margin-left: inherit;
        }
    }

    &.grid_row_section {
        display: grid;
        grid-template-columns: auto auto auto auto;

        .inputGroupSearch {
            width: 100%;
        }
    }
}

.edit_img_modal {
    img {
        margin-top: 10px;
        width: 100%;
        height: 10rem;
        object-fit: contain;
        object-position: left;
    }
}

.name_cate {
    margin-bottom: 15px;
    font-size: 18px;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        margin-left: 10px;
        font-size: 16px;
    }

    .table_btn {
        margin-left: 10px;
    }
}

.date_input input::-webkit-calendar-picker-indicator {
    background-image: url(../../../public/images/date_icon.svg);
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}

.css-1u9des2-indicatorSeparator {
    background-color: hsla(212, 66%, 34%, 0.6) !important;
}

.date_input {
    .form-control {
        text-transform: uppercase;
    }
}

.list_top_filter {
    display: flex;
    gap: 20px;
}

.addLeagueBlock {
    .addtitle {
        font-size: 25px;
        color: #fff;
        font-weight: 400;
        margin-bottom: 40px;
    }

    &.notifications_send {
        .addLeagueForm_block {
            &.user_list_edit {
                .ql-editor {
                    min-height: 18rem;
                    max-height: 22rem;
                }
            }
        }
    }

    .addLeagueForm_block {
        max-width: 800px;
        margin: auto;

        &.add_staff_page {
            max-width: 950px;
            // padding: 30px;
            // border-radius: 10px;
            // background: rgb(255 255 255 / 2%);
        }

        &.user_list_edit {
            max-width: none;

            .ql-toolbar.ql-snow {
                span {
                    color: #fff;
                }

                .ql-stroke {
                    stroke: #fff;
                }

                .ql-fill,
                .ql-stroke.ql-fill {
                    fill: #fff;
                }
            }
            .ql-editor {
                min-height: 35rem;
                max-height: 40rem;
            }
        }

        .imgUplInst {
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            margin-top: 5px;
            color: rgba(255, 255, 255, 0.7);
        }
        .uploadTemaLogo {
            position: relative;
            width: 125px;
            height: 125px;
            min-width: 125px;
            input[type="file"] {
                position: absolute;
                opacity: 0;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                right: -10px;
                top: -10px;
            }
            .upldLogo {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid $theme-color;
                display: flex;
                background: $scondary-color;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 15px;
                text-align: center;
                color: #fff;
                font-weight: 300;
                position: absolute;
                right: -10px;
                top: -10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background: $theme-color;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 5px;
                border: 2px solid $scondary-color;
            }
        }

        .formAddBlock {
            label {
                margin-bottom: 10px;
                font-size: 16px;
                width: 100%;
                font-weight: 300;
            }
            .form-group {
                margin-bottom: 30px;
            }
            .form-control {
                &::placeholder {
                    padding-left: 0;
                    color: $form-placeholoder;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                }

                &.select-control {
                    color: #d5d5d5;
                    background-image: url(./../../assets/select_dropdown.svg);
                    background-size: 16px;
                    background-repeat: no-repeat;
                    background-position: center right 2rem;
                }
            }
        }
    }
}

.inputGroupSearch.search_input {
    min-width: 360px;
    max-width: 360px;
}

.banner_img_box {
    position: relative;

    .image_banner_edit {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 50%;
        right: calc(50% + -50px);
        transform: translateY(-50%);
    }
}

.banner_img_box ::file-selector-button {
    background: rgba(10, 33, 57, 0);
    border-radius: 10px;
    border: 1px solid #4b6884;
    color: #d5d5d5;
    font-weight: 500;
    outline: 0;
    min-height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: 0.05em;
    font-size: 14px;
    min-width: 200px;
    margin-right: 20px;
}

.view_user_list_details {
    .detailBlock {
        display: flex;
        gap: 30px;
        align-items: center;
        label {
            font-size: 14px;
            color: #fff;
            white-space: nowrap;
            width: 40%;
        }

        span {
            color: #fff;
            font-size: 16px;
            display: flex;
            width: 60%;
            background: hsla(212, 66%, 34%, 0.5);
            align-items: center;
            padding: 0 20px;
            border-radius: 6px;
            min-height: 40px;
            font-weight: 400;

            .view_img {
                padding: 1rem 0;
                width: 25rem;
                height: 25rem;
                overflow: hidden;

                .img-fluid {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: left;
                }
            }
        }
    }
}

.price_breakup_section {
    margin-top: 30px;

    h4 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 15px;
    }
}

.table_midle_heading {
    margin: 30px 0 15px;
    font-size: 22px;
}

.pagination_entries_section {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    margin-top: 2rem;

    .entries_text {
        .common_input_filed {
            background-color: #144272;
            border: 1px solid #255c9d;
            margin-left: 0;
            margin-right: auto;
            order: -1;
            color: #fff;
            font-size: 16px;
            padding: 5px 8px;
            border-radius: 5px;

            &:focus-visible {
                outline: unset;
            }
        }
    }
}

@media (max-width: 1399px) {
    .searchAddRowTable {
        &.responsive_common {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}

@media (max-width: 767px) {
    .searchAddRowTable {
        flex-wrap: wrap;
        margin: 20px 0;
    }

    .addLeagueBlock {
        .addLeagueForm_block.add_staff_page {
            padding: 15px;
            border-radius: 5px;
        }

        .addLeagueForm_block.user_list_edit {
            .ql-editor {
                min-height: 20rem;
                max-height: 25rem;
            }
        }
    }

    .banner_img_box {
        ::file-selector-button {
            border-radius: 8px;
            min-height: 45px;
            min-width: 120px;
        }
    }
}

@media (max-width: 575px) {
    .view_user_list_details {
        .detailBlock {
            gap: 15px;
            flex-wrap: wrap;
        }
    }

    .view_user_list_details {
        padding: 20px;
    }

    .searchAddRowTable {
        &.responsive_common {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            margin: 20px 0;
            gap: 15px;
        }
    }

    .pagination_entries_section {
        flex-direction: column;
    }

    .pagination-container {
        .pagination-item {
            padding: 0 10px;
            height: 3rem;
            min-width: 3rem;
        }
    }

    .inputGroupSearch.search_input {
        min-width: 100%;
        max-width: 100%;
    }
}

.contest-breakup-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit_btn {
    background: transparent;
    color: #fdaa09;
    border: 0;
    padding: 0;
    font-size: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.points_system_table {
    padding: 0 0 10rem;

    .tab_main_section_list {
        .nav-tabs {
            justify-content: center;
        }

        .nav-tabs.second_tab_section {
            .nav-link {
                font-size: 16px;
                color: #001d3f;
                font-weight: 500;

                &::before {
                    border: 2px solid #001d3f;
                }

                &.active {
                    &::after {
                        background-color: #001d3f;
                    }
                }
            }
        }
    }
}

.point_table_box_inner {
    border: 1px solid #dadada;
    box-sizing: border-box;
    box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    ul {
        padding-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;

        li {
            display: flex;
            justify-content: space-between;
        }

        li:nth-child(odd) {
            background-color: #f5f5f5;
        }
    }
}

.top_content_points {
    text-align: center;
    color: #000;
    margin: 0 auto;
    padding: 6rem 0;
    background: #f5f5f5;

    .heading_points {
        width: 1000px;
        margin: 0 auto;
        padding: 0 2rem;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    p {
        font-size: 15px;
        color: #4d4d4d;
        font-weight: 400;
    }
}

.tab_list_points {
    .sportsTab {
        margin-bottom: 50px;
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(132, 132, 132, 0.15);
        border: 0;
        justify-content: center;
        gap: 30px;

        &.nav-tabs {
            .nav-link {
                padding: 8px 0;
                width: 100px;
                border: 0;
                position: relative;

                img {
                    width: 32px;
                    height: 32px;
                    display: block;
                    margin: 0 auto 4px;
                    filter: grayscale(1);
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #c6c9cc;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: #c6c9cc;
                    border-radius: 4px 4px 0 0;
                    height: 4px;
                    width: 100%;
                    opacity: 0;
                }

                &.active {
                    span {
                        color: #ffb806;
                    }

                    img {
                        filter: unset;
                    }

                    &:after {
                        opacity: 1;
                        background: #ffb806;
                    }
                }
            }
        }
    }
}

.mini-container {
    max-width: 1000px;
    margin: 0 auto;
}

.matchType {
    background: #fff;
    box-shadow: 0 5px 10px rgba(132, 132, 132, 0.15);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    border: 0;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: nowrap;

    &.nav-tabs {
        .nav-item {
            width: 100%;
        }
        .nav-link {
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #ffb806;
            width: 100%;
            border: 0;
            padding: 14px 10px;

            &.active {
                background-color: #ffb806;
                color: #fff;
            }
        }
    }
}

.pointtext {
    margin: 0;
    padding: 16px;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        line-height: 20px;
        color: #797979;
    }
}

.pointbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 89px;
    margin: 1px 0;
    color: #000;
}

.green {
    background: #0b9010;
    color: #fff;
}

.orange {
    background: #e55d42;
    color: #fff;
}

.point_table_header {
    padding-left: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../../../public/images/afl_batting_banner.png);

    &.bowling {
        background-image: url(../../../public/images/afl_bowling_banner.png);
    }

    &.fielding {
        background-image: url(../../../public/images/afl_fielding_banner.png);
    }

    &.other {
        background-image: url(../../../public/images/afl_other_banner.png);
    }

    h3 {
        margin: 0;
        font-size: 22px;
        font-weight: 400;
        color: #000000;
        padding-top: 35px;
    }
    p {
        margin: 0;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #797979;
        padding-bottom: 24px;
    }
}
