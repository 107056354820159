@import "../../../var";

html {
    font-size: 62.5%;
}

.leagueCard {
    background: $scondary-color;
    box-shadow:
        -0.3rem -0.3rem 0.8rem rgba(128, 52, 130, 0.5),
        0.5rem 0.5rem 2rem rgba(40, 5, 40, 0.65);
    padding: 3rem 2rem;
    border-radius: 0.3rem;
    display: flex;
    height: 100%;
    .leagueCard_content {
        flex: 0 0 calc(100% - 6.5rem);
        max-width: calc(100% - 6.5rem);
        padding-right: 1rem;
        .countLeagueCard {
            font-size: 3rem;
            font-weight: 500;
            color: $theme-color;
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
            span {
                font-size: 1.8rem;
                display: inline-block;
            }
        }
        .activeLeague {
            font-size: 1.6rem;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.8);
            margin-top: 1rem;
            span {
                color: $success-color;
                display: inline-block;
                margin-left: 0.5rem;
            }
        }
    }
    .leagueCard_icon {
        flex: 0 0 6.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5.5rem;
        color: $theme-color;
        align-self: center;
    }
}

.stats_card {
    background: #001D3F;
    border-radius: 1.2rem;
    // box-shadow: -2px -2px 9px rgba(40, 5, 40, 0.4);
    margin-top: 4.5rem;
    height: calc(100% - 4.5rem);
    display: flex;
    flex-direction: column;
    border: 1px solid #1D538F;
    .stats_card_top {
        padding: 2rem 3rem 0;
        display: flex;
        gap: 1.5rem;
        margin-bottom: 1.6rem;
        .stats_card_icon {
            width: 6.4rem;
            height: 6.4rem;
            min-width: 6.4rem;
            margin-top: -5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1.2rem;
            color: #ffffff;
            font-size: 3.5rem;
            box-shadow:
                rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
                rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
            background: #FDAA09;
            border: 1px solid #1D538F;
        }
        .infoGrap {
            display: flex;
            align-items: center;
            margin-top: 1.6rem;
            gap: 2rem;
            font-size: 1.4rem;
            transform: translateY(1rem);
        }
        .stats_card_title {
            margin-left: auto;
            text-align: right;
            span {
                font-size: 1.6rem;
                color: #fff;
                font-weight: 300;
                line-height: 1.5;
                letter-spacing: 0.1rem;
                display: inline-block;
            }
            h4 {
                font-size: 3.5rem;
                line-height: 1.375;
                font-weight: 700;
                letter-spacing: 0.1rem;
                color: $theme-color;
                margin-bottom: 0;
            }
        }
    }
    .activeInfo {
        display: inline-block;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            width: 0.8rem;
            height: 0.8rem;
            min-width: 0.8rem;
            border-radius: 50%;
            background: $success-color;
            margin-right: 0.8rem;
            display: inline-block;
            content: "";
        }
    }
    .inactiveInfo {
        display: inline-block;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            width: 0.8rem;
            height: 0.8rem;
            min-width: 0.8rem;
            border-radius: 50%;
            background: $error-color;
            margin-right: 0.8rem;
            display: inline-block;
            content: "";
        }
    }
    .maleInfo {
        display: inline-block;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            width: 0.8rem;
            height: 0.8rem;
            min-width: 0.8rem;
            border-radius: 50%;
            background: #2986cc;
            margin-right: 0.8rem;
            display: inline-block;
            content: "";
        }
    }
    .femaleInfo {
        display: inline-block;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            width: 0.8rem;
            height: 0.8rem;
            min-width: 0.8rem;
            border-radius: 50%;
            background: #d5a6bd;
            margin-right: 0.8rem;
            display: inline-block;
            content: "";
        }
    }
    .activeBadge {
        background: $success-color;
        display: inline-block;
        padding: 0.2rem 0.5rem;
        color: #fff;

        font-weight: 500;
        min-width: 3.5rem;
        height: 3.5rem;
        // width:3.5rem;
        text-align: center;
        line-height: 1.1;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .closeBadge {
        background: $error-color;
        display: inline-block;
        padding: 0.2rem 0.5rem;
        color: #fff;
        font-weight: 500;
        min-width: 3.5rem;
        height: 3.5rem;
        // width:3.5rem;
        text-align: center;
        line-height: 1.1;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .maleBadge {
        background: #2986cc;
        display: inline-block;
        padding: 0.2rem 0.5rem;
        color: #fff;
        font-weight: 500;
        min-width: 3.5rem;
        height: 3.5rem;
        // width:3.5rem;
        text-align: center;
        line-height: 1.1;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .femaleBadge {
        background: #d5a6bd;
        display: inline-block;
        padding: 0.2rem 0.5rem;
        color: #fff;
        font-weight: 500;
        min-width: 3.5rem;
        height: 3.5rem;
        // width:3.5rem;
        text-align: center;
        line-height: 1.1;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .stats_card_divider {
        margin: 1.6rem auto;
        height: 1px;
        opacity: .5;
        border: 1px solid #1D538F;
        width: calc(100% - 60px);    
        // background-image: linear-gradient(
        //     to right,
        //     rgba(255, 255, 255, 0),
        //     rgba(255, 255, 255, 0.7),
        //     rgba(255, 255, 255, 0)
        // );
    }
    .stats_card_bottom {
        padding: 0 3rem 1.6rem;
        display: flex;
        gap: 2.4rem;

        p {
            display: flex;

            align-items: center;
            margin-bottom: 0;
            flex: auto;
            font-size: 1.4rem;
            color: #fff;
            letter-spacing: 0.1rem;
            gap: 0.8rem;
            span.activeColor {
                color: $success-color;
                font-weight: 700;
                display: inline-block;
            }
            strong {
                color: #ffffff;
                // margin-right:.5rem
            }
        }
    }
}

// signupuser card

.signupUserCard {
    background: #001D3F;
    border-radius: 1.2rem;
    // box-shadow: -2px -2px 9px rgba(40, 5, 40, 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #1D538F;
    .signupUserCard_header {
        padding: 10px 20px;
        height: 58px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #1D538F;
    }
    .signupUserCard_body {
        padding: 25px 20px;
        flex: 1;
    }
    .titleSignupUser {
        font-size: 1.8rem;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
        span {
            display: inline-block;
            margin-left: auto;
            color: $theme-color;
            font-size: 2.5rem;
            font-weight: 700;
        }
    }
    .mapImg {
        min-height: 300px;
    }
}

.topPerformingClub {
    background: #001D3F;
    border: 1px solid #1D538F;
    border-radius: 1.2rem;
    // box-shadow: -2px -2px 9px rgba(40, 5, 40, 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    .mostSelectCLub {
        font-size: 16px;
        color: #fff;
        span {
            display: block;
            margin-top: 5px;
            color: $theme-color;
            font-size: 2.5rem;
            font-weight: 700;
        }
    }

    .clubGroup_row {
        display: flex;
        gap: 15px;
        margin-top: 15px;
        .clubGroup_col {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 15px;
            border-radius: 5px;
            border-radius: 10px;
            background: hsl(212deg 66% 34% / 50%);

            &.gender_col {
                flex-direction: row;
                gap: 15px;
                padding: 10px;
                align-items: center;
                .parentMale {
                    flex: 1;
                }
                .clubGroup_col_icon,
                .lowPerformingIcon {
                    background-color: #FDAA09 !important;
                    font-size: 2.4rem;
                    width: 4rem;
                    height: 4rem;
                    flex: 0 0 4rem;
                }
            }
            .clubGroup_col_icon {
                width: 30px;
                height: 30px;
                min-width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: rgb(11 144 16);
                &.lowPerformingIcon {
                    background-color: rgb(229 93 66);
                }
            }
            .clubGroup_col_title {
                font-size: 1.4rem;
            }
            .clubGroup_col_count {
                color: $theme-color;
                font-weight: 600;
                font-size: 1.8rem;
            }
        }
    }
    .transgenderCol {
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 10px;
        background: hsl(212deg 66% 34% / 50%);
        margin-top: 10px;
        padding: 10px;
        .clubGroup_col_title {
            font-size: 1.4rem;
        }
        .clubGroup_col_count {
            font-weight: 600;
            font-size: 1.8rem;
            color: $theme-color;
        }
        .clubGroup_col_icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $scondary-color;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.chipsRow {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 15px;
    padding: 15px;
    background: hsl(212deg 66% 34% / 50%);
    border-radius: 10px;
    flex: 1;
    margin-top: 15px;
    flex-wrap: wrap;
    .chipsRowHeading {
        flex: 0 0 100%;
        width: 100%;
        font-size: 1.6rem;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .chipsRow_col {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        font-size: 1.4rem;
        color: #fff;
        span {
            font-size: 1.8rem;
            color: $theme-color;
            font-weight: 600;
        }
        &:not(:last-child) {
            &::before {
                content: "";
                width: 1px;
                background: rgba(255, 255, 255, 0.2);
                position: absolute;
                right: 0;
                height: 70%;
                top: 50%;
                translate: 0 -50%;
            }
        }
    }
}

.activityParent {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.actvitList {
    display: flex;
    gap: 10px;
    align-items: center;
    .actiIcon {
        flex: 0 0 35px;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FDAA09;
        font-size: 22px;
        border-radius: 5px;
        color: #fff;
        // transform: translateY(5px);
    }
    .actiTitle {
        flex: 1;
        font-size: 14px;
    }
    .countAct {
        font-size: 15px;
        font-weight: 500;
        display: flex;
        gap: 10px;
        .upGrowth {
            color: $success-color;
        }
        .downGrowth {
            color: $error-color;
        }
    }
}

.deviceCard_mobile {
    display: flex;
    align-items: center;
    gap: 15px;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    .deviceCard_icon {
        flex: 0 0 50px;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        background: #FDAA09;
        border-radius: 6px;
        color: #fff;
    }

    .deviceCard_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
    .deviceTitle {
        font-size: 1.8rem;
    }
    .statsDevice {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.5);
        > svg {
            color: #fff;
            font-size: 16px;
        }
        span {
            margin-left: auto;
            display: flex;
            align-items: center;
            &.upUsers {
                color: $success-color;
                svg {
                    font-size: 20px;
                }
            }
            &.downUsers {
                color: $error-color;
                svg {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 1659.98px) {
    .stats_card {
        .stats_card_bottom {
            padding: 0 1.5rem 1.6rem;
            gap: 1.5rem;
        }
        .stats_card_top {
            padding: 2rem 1.5rem 0;
        }
    }

    .map_col_dashboard {
        width: 65%;
    }
    .mobile_col_dashboard {
        width: 35%;
    }
    .activity_col_dashboard,
    .mostSelect_col_dashboard,
    .chip_col_dashboard,
    .gender_col_dashboard,
    .favTeam_col_dashboard,
    .contribute_col_dashboard {
        width: 50%;
    }
}

@media (max-width: 1439.98px) {
    .leagueCard {
        padding: 2rem 1.5rem;
        font-size: 1.4rem;
        .leagueCard_content {
            flex: 0 0 calc(100% - 4.5rem);
            max-width: calc(100% - 4.5rem);
            .countLeagueCard {
                font-size: 2.5rem;
            }
        }
        .leagueCard_icon {
            font-size: 4.5rem;
            flex: 0 0 4.5rem;
        }
    }
}

@media (max-width: 1199.98px) {
    .map_col_dashboard {
        width: 100%;
    }
    .activity_col_dashboard,
    .mobile_col_dashboard {
        width: 50%;
    }

    .mostSelect_col_dashboard,
    .chip_col_dashboard,
    .gender_col_dashboard,
    .favTeam_col_dashboard,
    .contribute_col_dashboard {
        width: 100%;
    }
}

@media (max-width: 991.98px) {
    .stats_card {
        .stats_card_top {
            .stats_card_title {
                span {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .activity_col_dashboard,
    .mobile_col_dashboard {
        width: 100%;
    }

    .signupUserCard .signupUserCard_header {
        padding: 10px 15px;
        height: 55px;
    }
    .signupUserCard .titleSignupUser {
        font-size: 1.6rem;
    }
    .signupUserCard .titleSignupUser span,
    .topPerformingClub .mostSelectCLub span {
        font-size: 2.2rem;
    }
    .signupUserCard .signupUserCard_body {
        padding: 15px;
    }

    .deviceCard_mobile .deviceCard_icon {
        flex: 0 0 65px;
        height: 65px;
        width: 65px;
    }
    .deviceCard_mobile .deviceTitle,
    .topPerformingClub .clubGroup_row .clubGroup_col .clubGroup_col_count,
    .chipsRow .chipsRow_col span {
        font-size: 1.6rem;
    }
    .deviceCard_mobile:not(:last-child) {
        margin-bottom: 15px;
    }
    .topPerformingClub .clubGroup_row .clubGroup_col,
    .chipsRow {
        padding: 10px;
    }
    .topPerformingClub {
        padding: 15px;
    }
    .chipsRow .chipsRowHeading,
    .topPerformingClub .mostSelectCLub {
        font-size: 1.4rem;
    }

    .stats_card {
        margin-top: 1.5rem;
        height: calc(100% - 1.5rem);
    }
}
