@import "../../var";

.text-ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.themeTable {
    margin-bottom: 0;
    font-size: 14px;
    vertical-align: middle;
    
    &:not(.remove-word-wrap) {
        white-space: nowrap;
    }

    tr {
        border-bottom: 1px solid hsl(212deg 66% 34% / 50%);

        &:last-child {
            border-bottom: 0;
        }

        th,
        td {
            &:last-child {
                border-right: 0;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                // background-color: #2c74b338;

                td {
                    &:first-child {
                        // background: #0a3058;
                    }
                }
            }
        }
    }
    th {
        color: #fff;
        padding: 15px 30px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
        background: #144272;
        border-right: 1px solid hsla(212, 66%, 34%, 0.5);

        &:first-child {
            position: sticky;
            left: 0;
        }
        &:last-child {
            text-align: end;
        }

        .sortTableBtn {
            padding: 0;
            margin: 0;
            background: transparent;
            font-size: 20px;
            font-weight: 600;
            line-height: 0;
            color: #fff;
            border: 0;
            margin-left: 5px;
        }
    }
    td {
        color: #fff;
        padding: 15px 30px;
        border: 0;
        background: transparent;
        border-right: 1px solid hsla(212, 66%, 34%, 0.5);
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;

        &:first-child {
            position: sticky;
            left: 0;
            background: #001d3f;
        }
    }
    .userCol_table {
        display: flex;
        align-items: center;
        font-weight: 700;
        gap: 16px;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: contain;
            object-position: center;
            background: #f7f7f7;
        }
    }
    .btnTableGroup {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: end;

        button {
            box-shadow: none;
            outline: 0;
            width: 30px;
            height: 30px;
            min-width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: $scondary-color;
            border-radius: 50%;
            border: 0;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #fff;
                background: $theme-color;
            }
        }
    }
    .successMark {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $success-color;
    }
    .warningMark {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $warning-color;
    }
    .errorMark {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $error-color;
    }

    &.contest_table {
        th {
            &:last-child {
                text-align: start;
            }
        }
    }
}
