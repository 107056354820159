
.page-not-found{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #144272;

    h1{
        font-size: 6rem;
        font-weight: 500;
        margin-bottom: 15PX;
    }
    h2{
        font-size: 4.5rem;
        font-weight: 400;
        margin-bottom: 10px;
    }
    h3{
        font-size: 2.2rem;
        font-weight: 300;
        color: #fff;
    }
    .themeBtn{
        margin: 4rem auto 0;
        max-width: 20rem;
    }
}