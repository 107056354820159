.cms_landing_page{
    .accordion {
        padding: 100px 0;
    
        .accordion-item {
            margin-bottom: 20px;
            border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
            border-radius: 12px !important;
            overflow: hidden;
    
            .accordion-body {
                font-size: 15px;
                padding: 25px 15px;
            }
    
            .accordion-header {
                button {
                    padding: 25px 15px;
                    font-size: 16px;
    
                    &:focus{
                        border-color: transparent !important;
                        box-shadow: none;
                    }
    
                    &:not(.collapsed) {
                        background: #001d3f;
                        color: #fff;
    
                        &::after {
                            // background-color: #fff;
                            filter: brightness(1) invert(1);
                        }
                    }
                }
            }
        }
    }
}

