@keyframes dot-flashing {
	0% {
		background-color: #ffffff;
	}
	50%,100% {
		background-color: rgba(255, 255, 255, 0.2);
	}
}
.snippet {
    width: 60px;
    display: flex;
    justify-content: center;
	.dot-flashing {
		position: relative;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #ffffff;
		color: #ffffff;
		animation: dot-flashing 1s infinite linear alternate;
		animation-delay: 0.5s;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
			left: -15px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #ffffff;
			color: #ffffff;
			animation: dot-flashing 1s infinite alternate;
			animation-delay: 0s;
		}
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
			left: 15px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #ffffff;
			color: #ffffff;
			animation: dot-flashing 1s infinite alternate;
			animation-delay: 1s;
		}
	}
	&.black{filter: brightness(0);}
}


.logo-loader{
	text-align: center;
	h2{margin-top: 1rem;color: #000000;}
	&.fullScreenLoader{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
}