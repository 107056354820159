/* font family */
@font-face {
    font-family: 'Design';
    src: url('../../../public/landingfont/DesignRegular.eot');
    src: url('../../../public/landingfont/DesignRegular.eot?#iefix') format('embedded-opentype'),
        url('../../../public/landingfont/DesignRegular.woff2') format('woff2'),
        url('../../../public/landingfont/DesignRegular.woff') format('woff'),
        url('../../../public/landingfont/DesignRegular.ttf') format('truetype'),
        url('../../../public/landingfont/DesignRegular.svg#DesignRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@200;300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap');



/* 
    font-family: 'Design' , sans-serif;
    font-family: 'Bebas Neue', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
*/

:root {
    --scroll: 0;
}

.wow {
    visibility: hidden;
}

.clear {
    clear: both;
}

img:not(table img) {
    max-width: 100%;
}

iframe {
    border: 0;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.container {
    width: 100%;
    max-width: 152rem;
    padding: 0 2rem;
}

p {
    font-size: 16px;
    margin-bottom: 0;
}

figure {
    margin: 0 auto;
    display: block;
    text-align: center;
}

body.sidenavopen {
    overflow: hidden;
}



/* Header Start*/

header {
    display: flex;
    align-items: center;
    z-index: 10;
    position: relative;
    background: 0 0;
    position: absolute;
    left: 0;
    right: 0;
    /* max-width: 905px; */
    margin: auto;
    padding: 30px 0;
    -webkit-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
}

.navbar_header_web {
    justify-content: center;
}

.logo_landing {
    max-width: 220px;
}

header.is-sticky {
    position: fixed;
    right: 0;
    left: 0;
    top: -150px;
    transform: translateY(-100%);
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background:#111111;
    -webkit-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
    padding:10px 0;
}

/* header.is-sticky.awake {
    transform: translateY(0%);
    -webkit-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
}

header.is-sticky.inner_pages_head,
header.inner_pages_head {
    height: 65px;
} */

/*  Header End */


header.is-sticky.scroll-up {
    transform: translateY(0%);
    -webkit-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
    top: 0;
}

header .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.8rem;
    font-weight: 400;
    color: #fff;
    line-height: 2.4rem;
    padding: 0;
    transition: all .3s ease-in-out;
    position: relative;
}

header .navbar-expand-lg .navbar-nav .nav-link::before {
    content: '';
    background-color: #FFB806;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: calc(100% + 2px);
    margin: auto;
    left: 0;
    right: 0;
    transform: scale(0);
    transform-origin: center;
    transition: all .3s ease-in-out;
}

header .navbar-expand-lg .navbar-nav .nav-link:hover,
header .navbar-expand-lg .navbar-nav .nav-link.active {
    color: #FFB806;
}

/* header .navbar-expand-lg .navbar-nav .nav-link:hover::before , */
header .navbar-expand-lg .navbar-nav .nav-link.active::before{
    transform: scale(1);
}
header .navbar-expand-lg .navbar-nav .nav-link:hover::before,
header .navbar-expand-lg .navbar-nav  .nav-link.active:hover::before{
    transform: scale(0);
}
header .navbar-expand-lg .navbar-nav .nav-item:not(:last-child) {
    margin-right: 50px;
}

header .navbar-brand {
    margin: 0 50px;
    /* margin: 0; */
}

.navbar {
    padding: 0;
}

.back_top {
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111111;
    border-radius: 0;
    color: #fff;
    font-size: 30px;
    border: 2px solid transparent;
    z-index: 10;
    transform: translateY(200%);
}
.back_top img{
    rotate: -90deg;
    width: 20px;
}
.back_top.active {
    transform: translateY(0%);
}

.back_top:hover {
    border-color: #111111;
    background-color: #fff;
}





/*  banner */

.banner {
    background: url(../../../public/images/website/banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 1080px;
    padding: 130px 0 100px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
}
.banner::before{
    content:'';
    position: absolute;
    background:hsl(215deg 93.18% 10.35% / 85%);
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -2;
}

.heroShape,
.heroArrow{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* display: none; */
    translate: calc(-0.5% * (max(var(--scroll), 40) - 40) * 100 / 60) 0;
    opacity: calc(100% - 2% * min(var(--scroll), 40) * 100 / 90);
}
.heroArrow{
    left: -2px;
}
.heroShape img,
.heroArrow img{
    height: 100%;
    width: auto;
}
.banner .banner_details {
    flex: 1 1 0%;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    gap: 7rem;
}

.banner_title {
    font-size: 11.5rem;
    color: transparent;
    background: transparent;
    text-align: left;
    font-family: 'Design', sans-serif;
    -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: #FFFFFF;
    width: 100%;
    letter-spacing: 1.5px;
    max-width: 1208px;
    /* (oldVal - oldMin) * newRange / oldRange + newMin */
    translate: calc(1% * (max(var(--scroll), 10) - 10) * 100 / 90) 0;
    opacity: calc(100% - 2% * min(var(--scroll), 10) * 100 / 90);
}

.banner_title::first-letter {
    font-size: 12.7rem;
}

.rocky_text {
    font-size: 9.4rem;
    text-align: right;
    display: flex;
    column-gap: 30px;
    margin-top: 30px;
}

.banner_title span {
    -webkit-text-stroke-width: initial;
    -webkit-text-stroke-color: inherit;
}

.banner_title span.rocky11 {
    color: #FFB806;
}

.banner_title span.fantasy {
    color: #fff;
}



/* theme btn */

.themeBtn2 {
    min-width: 20rem;
    min-height: 6rem;
    font-size: 1.6rem;
    padding: 1.8rem 4.5rem;
    font-weight: 500;
    border: 1px solid #fff;
    border-radius: inherit;
    transition: 0.3s;
    color: #fff;
    display: flex;
    gap: 1.8rem;
    background: transparent;
    position: relative;
}

.themeBtn2:hover {
    color: #FFB806;
}

.themeBtn2::before,
.themeBtn2::after {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    transition: all .5s ease;
}

.themeBtn2::before {
    border-left: 1px solid #FFB806;
    border-right: 1px solid #FFB806;
    height: 0;
    bottom: -1px;
    left: -1px;
}

.themeBtn2:hover::before {
    height: calc(100% + 2px);
    top: -1px;
}

.themeBtn2::after {
    border-top: 1px solid #FFB806;
    border-bottom: 1px solid #FFB806;
    width: 0;
    right: -1px;
    top: -1px;
}

.themeBtn2:hover::after {
    width: calc(100% + 2px);
    left: -1px;
}



.themeBtn2_2 {
    border: 0;
    box-shadow: none;
    outline: 0;
    position: relative;
    padding: 0;
    background:transparent;
     display: flex;
    align-items: center;
    gap: 18px;
    justify-content: center;
    position: relative;
    background: rgb(17, 17, 17);
    background: -moz-linear-gradient(270deg, rgb(0 24 57) 0%, rgb(11 50 100) 100%);
    background: -webkit-linear-gradient(270deg, rgb(0 24 57) 0%, rgb(11 50 100) 100%);
    background: linear-gradient(270deg, rgb(0 24 57) 0%, rgb(11 50 100) 100%);
    /* background-repeat: no-repeat; */
    min-width: 200px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.1rem;
    color: #fff;
    transition: all .3s ease-in-out;
    min-height: 60px;
}
.themeBtn2_2 img{
    transition: all .3s ease-in-out;
}
.themeBtn2_2:hover img{
    transform:translateX(10px)
}

.themeBtn2_2:hover {
    background-position: 200px 0;
    background-size: 300%;
}







/* about us */

.about_us {
    position: relative;
    padding: 6rem 0rem 12rem;
    overflow: hidden;
}

.row_spece {
    margin-top: -170px;
}

.about_us:before {
    content: '';
    position: absolute;
    right: -35px;
    top: 0;
    background: url(../../../public/images/website/gradient-before.png);
    width: 1121px;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: right center; */
    z-index: 1;
    top:calc(-0.5% * (max(var(--scroll), 90) - 80) * 100 / 80) ; 
}

.light_heading {
    font-size: 20rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #F0F0F0;
    text-align: center;
    line-height: 11rem;
    margin-top: 65px;
    user-select: none;
    /* transition: all .3s linear; */
    /* transform:translateX(450px) */
    /* translate: calc(-0.5% * (max(var(--scroll), -60) - 60) * 100 / 23) 0; */
    translate: calc(-0.5% * (max(var(--scroll), 40) - 95) * 120 / 30) 0; 
}

.row_spece {
    margin-top: -190px;
}

.aboutHeading_content {
    padding-bottom: 3rem;
    max-width: 888px;
}

.aboutHeading_content .heading {
    font-size: 8rem;
    color: #111111;
    font-family: 'Bebas Neue', sans-serif;
    line-height: 1.1;
}

.aboutHeading_content p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    margin-bottom: 0;
    color: #1D1D1D;
}

.mobile_img {
    position: relative;
    z-index: 1;
}



/* how to play section */

.howToPlay {
    background-image: url(../../../public/images/website/how_play.jpg);
    background-size: cover;
    position: relative;
    min-height: 1080px;
    padding-top: 110px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    display: flex;
    overflow: hidden;
}


.howToPlay::before {
    content: url(../../../public/images/website/repeatGrid.svg);
    position: absolute;
    width: 100%;
    height: 100%;
    background: hsla(215, 93.18%, 10.35%, 0.85);
    inset: 0;
    display: flex;
    opacity: 0.87;
    align-items: center;
    justify-content: center;
    z-index: -1;
}


.hotToPlay_col_1 {
    flex: 0 0 600px;
    max-width: 600px;
    width: 100%;
    padding: 0;
    padding-left: 60px;
    align-self: flex-end;
}

.hotToPlay_col_1 img {
    width: 100%;
}

.hotToPlay_col_2 {
    flex: 0 0 calc(100% - 600px);
    max-width: calc(100% - 600px);
    width: 100%;
}

.howToPlayHeading {
    font-size: 16rem;
    line-height: 22rem;
    color: rgba(255, 255, 255, 0.1);
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 50px;
    // translate: calc(-0.5% * (max(var(--scroll), 130)  - 208) * 100 / 25)  0 ;
}

.howPlay_contentBlock {
    max-width: 625px;
    margin: 112px 0 70px 200px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.commonHeading {
    font-size: 8rem;
    line-height: 9.6rem;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    color: #fff;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}

.howPlay_contentBlock p {
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
    padding-left: 20px;
    position: relative;
    border-left: 5px solid #FFB806;
}


.mobileMaskParent {
    position: relative;
}

.swiperParentRelate {
    position: absolute;
    top: 14px;
    width: 100%;
    max-width: 333px;
    left: 1px;
    right: 0;
    margin: auto;
}


.swiperCard_how {
    border-radius: 30px;
}

.swiper-pagination-how {
    display: flex;
    align-items: center;
    gap: 90px;
    margin-left: 200px;
}

.swiper-pagination-how .swiper-pagination-bullet {
    font-size: 22.5rem;
    line-height: 20rem;
    color: #fff;
    background: transparent;
    width: auto;
    height: auto;
    opacity: 1;
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    color: rgba(255, 255, 255, .04);
    transition: all .3s ease-in-out;
    -webkit-text-stroke: 1px transparent;

}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    width: auto;
}

.swiper-pagination-how .swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, .5);
}

/* download section  */

.downloadSection {
    padding: 100px 0;
    background: #111111;
    text-align: center;
}

.downloadSection p {
    line-height: 3.2rem;
    font-size: 2.2rem;
    color: #fff;
    margin-top: 30px;
}

.mailGroup {
    max-width: 500px;
    margin: 70px auto 0;
    display: flex;
}

.mailGroup .form-control {
    border-radius: 0;
    height: 60px;
    border: 1px solid #fff;
    background: transparent;
    box-shadow: none;
    outline: 0;
    padding: 5px 20px;
    font-size: 1.6rem;
    color: #fff;
    flex: 1;
    border-right: 0;
    transition: all .3s ease-in-out;
}

.mailGroup .form-control::placeholder {
    color: rgba(255, 255, 255, .42)
}

.mailGroup .form-control:focus {
    border-color: #FFB806;
}

.mailGroup .appBtn {
    background: #FFB806;
    height: 60px;
    width: 160px;
    font-size: 1.6rem;
    color: #090909;
    display: flex;
    align-items: center;
    border: 0;
    line-height: 2.1rem;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    flex: 0 0 160px;
}


/* client section */

.clientSection {
    padding: 100px 0 80px;
    position: relative;
    min-height: 550px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientLogo_row {
    display: flex;
    gap: 104px;
    justify-content: center;
    align-items: center;
}

.clientSection::before {
    content: url(../../../public/images/website/blace_repeat_grid.svg);
    position: absolute;
    z-index: -1;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientLogo_card {
    filter: url(#clientOver);
    transition: all .3s linear;
}

.clientLogo_card:hover,
.clientLogo_card.active {
    filter: unset;
    cursor: pointer;
}


/* feature section  */

.featureSection .commonHeading {
    color: #111111;
    text-align: center;
    margin: 4rem 0;
}


.featureBack_title {
    display: none;
}
.featureHeadingAnime{
    translate: 0 calc(-0.5% * (max(var(--scroll), 300)  - 390) * 45 / 25)   ; 
}



.featrueCard_row {
    display: flex;
    width: 100%;
}

.featrueCard_row .swiper{
    width: 100%;
}
.featureCard {
    /* flex: 0 0 50%;
    max-width: 50%; */
    width: 100%;
    display: flex;
    height: 750px;
}

.featureCard .featureCard_col {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
}

.featureCard .featureCard_col img {
    height: 100%;
    width: 100%;
}

.featureCard_col_content {
    background-color: #FFB806;
    height: 100%;
    padding: 10px 45px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}

.featureTopTitles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 6rem;
}

.featureCard_col_content h4 {
    font-size: 4rem;
    line-height: 4.8rem;
    color: #040404;
    font-family: 'Bebas Neue', sans-serif;
}

.featureCard_col_content p {
    font-size: 2.2rem;
    line-height: 3.2rem;
    color: #040404;
    margin: 0;
}

.featureCard_col_content h3 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 24.8rem;
    line-height: 29.7rem;
    color: rgba(0, 0, 0, .08);
    text-transform: uppercase;
}



/* testimonials */

.testimonials_section {
    padding: 130px 0 70px;
    overflow: hidden;
}

.testimonials_section .commonHeading {
    color: #111111;
    margin-bottom: 130px;
}

.testimonials_section .featureBack_title {
    margin-bottom: 0;
    margin-top: 30px;
}

.testimonialBlock {
    max-width: 805px;
    margin: 50px auto 0;
    position: relative;
    z-index: 1;
}

.testimonialBlock::before {
    content: url(../../../public/images/website/quoteIcon.svg);
    position: absolute;
    left: 0;
    top: 15px;
    z-index: -1;
}

.testimonialBlock::after {
    content: url(../../../public/images/website/quoteIcon.svg);
    position: absolute;
    right: 0;
    bottom: -25px;
    z-index: -1;
    rotate: 180deg;
}

// .testiHeading{
//     translate:   calc(-1% * (max(var(--scroll), 400) - 500) * 100 / 248) 0; 
// }


.testimonial_card {
    text-align: center;
}

.testimonial_card img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin: auto;
}

.testimonial_card .userName {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.6rem;
    color: #111111;
    margin-top: 20px;
}

.testimonial_card .userDesgination {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.1rem;
    color: #1D1D1D;
    margin-top: 4px;
}

.testimonial_card .userContent_review {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.6rem;
    color: #1D1D1D;
    max-width: 600px;
    margin: 18px auto 0;
}

.testimonial_card .userWinAmt {
    font-size: 4rem;
    line-height: 5.4rem;
    font-weight: 700;
    color: #000;
    margin-top: 15px;
}




.testiArrowGroup button {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #111111;
    position: absolute;
    top: 50%;
    translate: 40px -50%;
    transition:  all .3s ease-in-out;
    opacity: 0;
}

.testiArrowGroup button img {
    transition: all .3s ease-in-out;
}

.testiArrowGroup button.backArrowBtn img {
    rotate: 180deg;
}

.testiArrowGroup button.backArrowBtn {
    left: 0;
}

.testBlockParent:hover  .testiArrowGroup button.swiper-button-disabled {
    opacity:.5;
}

.testiArrowGroup button.nextArrowBtn {
    right: 0;
    translate: -40px -50%;
}

.testiArrowGroup button:not(.swiper-button-disabled):hover img {
    transform: translateX(5px);
}
.testBlockParent:hover .testiArrowGroup button{
    translate: 0 -50%;
    opacity: 1;
}

/* get app section  */
.getAppSection {
    padding: 85px 0 0;
    background: #FFB806;
}

.getAppSection .commonHeading {
    color: #111111;
}

.appGetcontnt p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #1D1D1D;
    margin-top: 15px;
}

.getAppGroup {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 40px;

    button{
        padding: 0;
        border: none;
        background-color: transparent;
        transition: all 0.3s ease-in-out;

        &:hover{
            transform: scale(1.1);
        }
    }
}

.appGetcontnt {
    max-width: 535px;
    margin-left: auto;
    padding-bottom: 85px;
}

.getAppGroup img {
    max-height: 50px;
}

.getAppImg {
    margin-top: -189px;
    text-align: center;
}


/* footer wrapper */

.footer_wrapper {
    padding: 70px 0 0;
    background-color: #001D3F;
}

.footerLogo {
    text-align: center;
}

.footerAddress {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin-top: 28px;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.1rem;
}

.footerAddress_content {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    a{
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.footerAddress_content:not(:last-child)::after {
    content: '|';
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #fff;
    margin-left: 5px;
}

.footerLinkMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    gap: 10px;
    padding: 0;
    list-style: none;
    line-height: 1;
}

.footerLinkMenu .footer_menu_main .footer_link {
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #fff;
    transition: all .3s ease-in-out;
    display: inline-block;
    text-decoration: none;
    position: relative;
}

.footerLogo img {
    max-width: 210px;
}

.footerLinkMenu .footer_link::after {
    content: '|';
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;

    &:last-child{
        content: unset;
    }
}

.footerLinkMenu .footer_link { 
    &::after{
        content: '|';
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #fff;
        margin-left: 10px;
        margin-right: 10px;
    }
    &:last-child{
        &::after{
            content: unset;
        }
    }
}

.footerLinkMenu .footer_menu_main .footer_link:hover {
    color: #FFB806;
}

.copyRightBlock {
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    align-items: center;
    gap: 15px;
}

.copyRightText {
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #fff;
}



/* swiper  */
.swiper-wrapper {
    align-items: center;
}




/* cursor */
 .cursor {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid #FFB806;
    /* border: 2px solid rgba(220,90,90,1);
    background:rgba(220,90,90,1); */
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    /* opacity: .9; */
    z-index: 9999;
    transform: translate(calc(-50% + 15px), -50%);
  }
  
  .cursor2 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #FFB806;
    /* background-color: rgba(220,90,90,1); */
    opacity:1;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 9999;
  }
  .cursorinnerhover {
    width:30px;
    height: 30px;
    opacity: .5;
  }

  .hover{
    width: 60px;
    height: 60px;
    opacity: 0;
    box-shadow: 0 0 10px 0 rgba(255,184,6, .05);
  }


  .top_min {
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: 100%;
}

.landing_layouts_section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main_footer {
        margin-top: auto;
    }
}











/* about us  */

.otherPage_title {
    font-family: 'Design', sans-serif;
    font-size: 7.4rem;
    color: #FFB806;
}
.otherPage_title span{
    color:#fff;
}
section.banner.otherPageBanner {
    min-height: 315px;
    background-position: center;
    padding: 100px 0;
}

.transUnset{
    translate: unset;
}
.contentOtherPage{
    padding: 20px 0 120px;
}

.headingAbout_content{
    font-size: 7rem;
    color:#111111;
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Bebas Neue', sans-serif;
}

.subHeading_about{
    font-size: 2.5rem;
    color:#111111;
    font-weight: 600;
    margin-bottom: 25px;
}
.content_about p{
    font-size: 1.6rem;
    color:#1D1D1D;
    margin-bottom: 20px;
}

ul.aboutList{
    list-style: none;
    padding:0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    counter-reset: my-sec-counter;
}
ul.registerList{
    list-style: none;
    padding:0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    counter-reset:reg-counter;
}

ul.aboutList > li{
    font-weight: 500;
    position: relative;
    padding-left: 30px;
}
ul.aboutList > li::before{
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter) ". ";
    color: #000;
    font-size: 17px;
    font-weight: 700;
    position: absolute;
    left: 0;
    top:0
}
ul.aboutList > li  li{
    font-size: 14px;
    margin-top: 10px;
}
ul.registerList li{
    font-weight: 500;
    position: relative;
    padding-left: 70px;
}
ul.registerList li::before{
    counter-increment: reg-counter;
    content:"Step " counter(reg-counter) ". ";
    color: #000;
    font-size: 17px;
    font-weight: 700;
    position: absolute;
    left: 0;
    top:-2px
}

ul.aboutList li span{
    font-size: 2rem;
    font-weight: 700;
    display: block;
    margin-bottom: 5px;
}




.contactSection{
    padding: 60px 0;
}

.light_heading{
   visibility: hidden;
}

.support_contact_section {
    padding: 100px 0 200px;
}


.contactCard {
    display: flex;
    gap: 30px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 50px;

    &:not(:last-child){
        margin-bottom: 50px;
    }
}

.contact_form {
    background: #fff;
    border-radius: 0.6rem;
    box-shadow: 0 5px 20px rgb(0 0 0 / 8%);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4rem;
    border-radius: 20px;
}

.common_label {
    width: 100%;
    margin-bottom: 10px;
    color: #111111;
    font-weight: 500;
    font-size: 18px;
}

.common_input {
    border: 1px solid #d6d6d6;
    border-radius: .4rem;
    color: #111727;
    font-size: 1.4rem;
    font-weight: 400;
    height: 5rem;
    padding: 0 2rem;
    width: 100%;

    &:focus, &:focus-visible {
        border: 1px solid #111727;
        outline: initial;
    }

    &.text_area {
        height: auto;
        padding: 1.5rem 2rem;
    }
}

.input_tag_card {
    margin-bottom: 3rem;
}

.contactCard  .contactIcon_card{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    color:#fff;
    flex: 0 0 70px;
    background: #FFB806;
    border-radius: 50%;
    justify-content: center;
    font-size: 35px;
}

.contactCard  .contactCard_detail span{
    font-family: 'Design', sans-serif;
    font-size: 2rem;
    display: block;
}
.contactCard  .contactCard_detail{
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    color: #1D1D1D;
}

.contactCard  .contactCard_detail span{
    font-weight: 500;
}


header.headerOther {
    position: relative;
    background: #111111;
    padding: 20px 0;
}